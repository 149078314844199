const $ = require('jquery/dist/jquery.slim');
const YouTubePlayer = require('youtube-player');


class VideoPlayer {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;
        this.videoId = this.$node.attr('data-video-id');

        this.$playerNode = this.$node.find('[data-ref~="player"]');
        this.$triggerNode = this.$node.find('[data-ref~="trigger"]');

        this.$triggerNode.on('click', this.handleTriggerClick.bind(this));
    }

    handleTriggerClick () {
        if (!this.player) {
            this.player = YouTubePlayer(this.$playerNode.get(0), {
                videoId: this.videoId,
                playerVars: {
                    color: 'white',
                    enablejsapi: 1,
                    modestbranding: 1,
                    playsinline: 1,
                    rel: 0,
                },
            });
            this.$playerNode.get(0).scrollIntoView();
        }

        this.player.playVideo();
    }
}

module.exports = VideoPlayer;
