const $ = require('jquery/dist/jquery.slim');


class TabbedContent {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');

        this.$node.on('click', '[data-ref~="trigger"]', this.handleTriggerClick.bind(this));
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.target);
        const index = this.$triggerNodes.index($triggerNode);

        this.$triggerNodes.removeClass('is-active')
            .attr('aria-selected', 'false');
        this.$itemNodes.removeClass('is-active')
            .attr('aria-hidden', 'true');

        this.$triggerNodes.eq(index).addClass('is-active')
            .attr('aria-selected', 'true');
        this.$itemNodes.eq(index).addClass('is-active')
            .attr('aria-hidden', 'false');
    }
}

module.exports = TabbedContent;
