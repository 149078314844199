require('focus-within-polyfill');
require('intersection-observer');
const $ = require('jquery/dist/jquery.slim');


const components = {
    'animation-observer': require('./components/animation-observer'),
    'carousel': require('./components/carousel'),
    'disclosure-list': require('./components/disclosure-list'),
    'header': require('./components/header'),
    'hero-carousel': require('./components/hero-carousel'),
    'in-page-nav': require('./components/in-page-nav'),
    'tabbed-content': require('./components/tabbed-content'),
    'video-player': require('./components/video-player'),
};

const initComponents = function (components) {
    $('[data-component]').each(function() {
        const node = this;
        $(node).data('component').split(/\s+/).forEach(function(componentName) {
            const component = components[componentName];
            if (component) {
                new component(node);
            }
        });
    });
};

$(function() {
    initComponents(components);
});
