const $ = require('jquery/dist/jquery.slim');
const throttle = require('throttle-debounce').throttle;


class HeroCarousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.selected = 0;

        this.$innerNode = this.$node.find('[data-ref~="inner"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');
        this.$prevNode = this.$node.find('[data-ref~="prev"]');
        this.$nextNode = this.$node.find('[data-ref~="next"]');
        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');

        this.$prevNode.on('click', this.handlePrevClick.bind(this));
        this.$nextNode.on('click', this.handleNextClick.bind(this));
        this.$triggerNodes.on('click', this.handleTriggerClick.bind(this));
        this.$innerNode.on('scroll', throttle(100, this.handleScroll.bind(this)));
        $(window).on('load resize', this.handleScroll.bind(this));
    }

    handlePrevClick () {
        if (this.selected > 0) {
            this.scrollToItem(this.selected - 1);
        } else {
            this.scrollToItem(this.$itemNodes.length - 1);
        }
    }

    handleNextClick () {
        if (this.selected < this.$itemNodes.length - 1) {
            this.scrollToItem(this.selected + 1);
        } else {
            this.scrollToItem(0);
        }
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.currentTarget);
        const index = this.$triggerNodes.index($triggerNode);
        this.scrollToItem(index);
    }

    handleScroll () {
        let itemNode;
        let itemLeft;

        this.$itemNodes.each((i, elem) => {
            const left = Math.abs(elem.getBoundingClientRect().left);
            if (!itemNode || left < itemLeft) {
                itemNode = elem;
                itemLeft = left;
            }
        });

        if (!itemNode) return;
        const $itemNode = $(itemNode);
        this.selected = this.$itemNodes.index($itemNode);

        this.$triggerNodes.removeClass('is-active');
        this.$triggerNodes.eq(this.selected).addClass('is-active');
    }

    scrollToItem (index) {
        const $itemNode = this.$itemNodes.eq(index);
        const left = this.$innerNode.scrollLeft() + $itemNode.get(0).getBoundingClientRect().left;

        if ('scrollTo' in this.$innerNode.get(0)) {
            this.$innerNode.get(0).scrollTo({
                top: 0,
                left,
                behavior: 'smooth',
            });
        } else {
            this.$innerNode.scrollLeft(left);
        }
    }
}

module.exports = HeroCarousel;
