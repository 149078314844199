const $ = require('jquery/dist/jquery.slim');
const throttle = require('throttle-debounce').throttle;


class Header {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$openNode = this.$node.find('[data-ref~="open"]');
        this.$closeNode = this.$node.find('[data-ref~="close"]');

        this.$openNode.on('click', this.handleOpenClick.bind(this));
        this.$closeNode.on('click', this.handleCloseClick.bind(this));

        $(window).on('scroll resize load', throttle(100, this.handleScroll.bind(this)));
    }

    handleCloseClick () {
        this.$node.removeClass('is-open');
    }

    handleOpenClick () {
        this.$node.addClass('is-open');
    }

    handleScroll () {
        this.$node.toggleClass('is-sticky', $(window).scrollTop() > 0);
    }
}

module.exports = Header;
