const $ = require('jquery/dist/jquery.slim');
const throttle = require('throttle-debounce').throttle;


class InPageNav {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$anchorNodes = this.$node.find('[data-ref~="anchor"]');
        this.findTargetNodes();

        $(window).on('scroll resize load', throttle(100, this.handleScroll.bind(this)));
    }

    findTargetNodes () {
        this.$anchorNodes.each((i, elem) => {
            const $node = $(elem);
            const $targetNode = $($node.attr('href'));
            $node.data('targetNode', $targetNode.length === 1 ? $targetNode.first() : null);
        });
    }

    handleScroll () {
        const viewportHeight = $(window).height();

        this.$anchorNodes.each((i, elem) => {
            const $node = $(elem);
            const $targetNode = $node.data('targetNode');
            if ($targetNode) {
                const top = $targetNode[0].getBoundingClientRect().top;
                // NOTE: This logic assumes the target nodes appear in the DOM
                // in sequential order
                if (top < viewportHeight / 2) {
                    this.$anchorNodes.removeClass('is-active');
                    $node.addClass('is-active');
                } else {
                    $node.removeClass('is-active');
                }
            }
            if (!$targetNode.is(':visible')) {
                console.warn(`Item with text "${$node[0].innerText}" and target "${$node.attr('href')}" targets non-visible node`);
            }
        });
    }
}

module.exports = InPageNav;
