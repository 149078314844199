const $ = require('jquery/dist/jquery.slim');
const throttle = require('throttle-debounce').throttle;


class Carousel {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$listNode = this.$node.find('[data-ref~="list"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');
        this.$prevNode = this.$node.find('[data-ref~="prev"]');
        this.$nextNode = this.$node.find('[data-ref~="next"]');

        this.$prevNode.on('click', this.handlePrevClick.bind(this));
        this.$nextNode.on('click', this.handleNextClick.bind(this));
        this.$listNode.on('scroll', throttle(100, this.handleScroll.bind(this)));
        $(window).on('load resize', this.handleScroll.bind(this));
    }

    handleScroll () {
    }

    handlePrevClick () {
        const listNode = this.$listNode.get(0);
        if (listNode.scrollLeft <= 10) {
            const newLeft = listNode.scrollWidth;
            if ('scrollTo' in listNode) {
                listNode.scrollTo({
                    top: 0,
                    left: newLeft,
                    behavior: 'smooth',
                });
            } else {
                listNode.scrollLeft = newLeft;
            }
            return;
        }

        const $centreItemNode = this.findCentreItem();
        if (!$centreItemNode) return;
        const idx = this.$itemNodes.index($centreItemNode);
        if (idx === -1) return;
        const $prevItemNode = this.$itemNodes.eq(idx - 1);
        this.scrollToItem($prevItemNode);
    }

    handleNextClick () {
        const listNode = this.$listNode.get(0);
        if (listNode.scrollLeft + listNode.clientWidth >= listNode.scrollWidth - 10) {
            const newLeft = 0;
            if ('scrollTo' in listNode) {
                listNode.scrollTo({
                    top: 0,
                    left: newLeft,
                    behavior: 'smooth',
                });
            } else {
                listNode.scrollLeft = 0;
            }
            return;
        }

        const $centreItemNode = this.findCentreItem();
        if (!$centreItemNode) return;
        const idx = this.$itemNodes.index($centreItemNode);
        if (idx === -1 || idx === this.$itemNodes.length - 1) return;
        const $nextItemNode = this.$itemNodes.eq(idx + 1);
        this.scrollToItem($nextItemNode);
    }

    findCentreItem () {
        const windowCentre = $(window).width() / 2;
        let $item;
        let itemDistance;
        this.$itemNodes.each((i, elem) => {
            const $elem = $(elem);
            const { left, width } = $elem.get(0).getBoundingClientRect();
            const centre = left + (width / 2);
            const distance = Math.abs(windowCentre - centre);
            if (!$item || distance < itemDistance) {
                $item = $elem;
                itemDistance = distance;
            }
        });
        return $item;
    }

    scrollToItem ($itemNode) {
        const windowCentre = $(window).width() / 2;
        const { left, width } = $itemNode.get(0).getBoundingClientRect();
        const centre = left + (width / 2);
        const distance = centre - windowCentre;
        const newLeft = this.$listNode.scrollLeft() + distance;

        if ('scrollTo' in this.$listNode.get(0)) {
            this.$listNode.get(0).scrollTo({
                top: 0,
                left: newLeft,
                behavior: 'smooth',
            });
        } else {
            this.$listNode.scrollLeft(newLeft);
        }
    }
}

module.exports = Carousel;
