const $ = require('jquery/dist/jquery.slim');


class DisclosureList {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$triggerNodes = this.$node.find('[data-ref~="trigger"]');

        this.$node.on('click', '[data-ref~="trigger"]', this.handleTriggerClick.bind(this));
    }

    handleTriggerClick (event) {
        const $triggerNode = $(event.target);
        const $itemNode = $triggerNode
            .closest('[data-ref="item"]')
            .find('[data-ref="description"]');

        $triggerNode.toggleClass('is-open');
        $triggerNode.attr('aria-expanded', $triggerNode.hasClass('is-open'));

        $itemNode.toggleClass('is-open', $triggerNode.hasClass('is-open'));
    }
}

module.exports = DisclosureList;
