const $ = require('jquery/dist/jquery.slim');


class AnimationObserver {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;
        this.$animatedNodes = this.$node.find('[data-ref~="animate"]');

        this.intersectionThreshold = [0, 0.25, 0.5, 0.75, 1];
        this.targetIntersectionRatio = 0.75;
        this.delayInterval = 100;

        this.init();
    }

    init () {
        if (!this.$node.css('position') || this.$node.css('position') === 'static') {
            this.$node.css('position', 'relative');
        }

        const $topSentinelNode =
            $('<div class="animation-observer-sentinel" />')
                .appendTo(this.$node);
        const $bottomSentinelNode =
            $('<div class="animation-observer-sentinel animation-observer-sentinel--bottom" />')
                .appendTo(this.$node);

        const observer = new IntersectionObserver(this.handleIntersection.bind(this), {
            threshold: this.intersectionThreshold,
        });

        observer.observe($topSentinelNode.get(0));
        observer.observe($bottomSentinelNode.get(0));
    }

    handleIntersection (entries, observer) {
        entries.forEach((entry) => {
            if (entry.intersectionRatio >= this.targetIntersectionRatio) {
                this.$animatedNodes.each((i, elem) => {
                    const $animatedNode = $(elem);
                    window.setTimeout(() => {
                        $animatedNode.addClass('is-animating-in');
                    }, i * this.delayInterval);
                });
                observer.disconnect();
            }
        });
    }
}

module.exports = AnimationObserver;
